<template>
  <div>
    <query-form
      class="content_block"
      label-width="120px"
      :keepOpenedRow="3"
      @search="handleSearch"
      @reset="handleReset"
    >
      <el-form-item label="待办类型：" :span="24">
        <check-tag v-model="form.taskTypeEnum" @change="handleSearch">
          <check-tag-item value="AGENT" label="待办"></check-tag-item>
          <check-tag-item value="INITIATE" label="我发起的"></check-tag-item>
          <check-tag-item value="DONE" label="已办"></check-tag-item>
        </check-tag>
      </el-form-item>
      <el-form-item label="单据编号：" prop="objCode" :offset="18" :span="8">
        <el-input v-model="form.objCode" placeholder="请输入单据编号"></el-input>
      </el-form-item>
      <el-form-item label="单据类型：" :span="8">
        <el-select v-model="form.customizeBusinessTypeCode">
          <el-option :value="undefined" label="全部">全部</el-option>
          <el-option
            v-for="modelItem in modelAll"
            :key="modelItem.id"
            :value="modelItem.code"
            :label="modelItem.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交人：" prop="approveUserName" :span="8">
        <el-input v-model="form.approveUserName" placeholder="请输入提交人"></el-input>
      </el-form-item>
      <el-form-item label="提交时间：" prop="timePeriod" :span="8">
        <el-date-picker
          style="width: 100%;"
          v-model="form.timePeriod"
          type="daterange"
          range-separator="-"
          :default-time="['00:00:00', '23:59:59']"
          value-format="timestamp"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="handleSearch"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="发起人：" prop="submitUserName" :span="8">
        <el-input v-model="form.submitUserName" placeholder="请输入发起人"></el-input>
      </el-form-item>
      <el-form-item label="发起时间：" prop="createdTime" :span="8">
        <el-date-picker
          style="width: 100%;"
          v-model="form.createdTime"
          type="daterange"
          range-separator="-"
          :default-time="['00:00:00', '23:59:59']"
          value-format="timestamp"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="handleSearch"
        ></el-date-picker>
      </el-form-item>
    </query-form>
    <table-with-pagination
      class="content_block"
      :tableData="customerList"
      :columnList="columnList"
      :pagination.sync="pagination"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { dateFormat } from '@/utils/dateFormat.js';
import { workbenchHandle } from '@/api/orderwall.js';
import { getFormTypeListByConfigId } from '@/api/commonForm';

export default {
  mixins: [TablePaginationMixin],
  data () {
    return {
      dateFormat,
      form: {
        taskTypeEnum: 'AGENT',
        objCode: '',
        approveUserName: '',
        approveStartTime: '',
        approveEndTime: '',
        submitUserName: '',
        submitStartTime: '',
        submitEndTime: '',
        timePeriod: [],
        createdTime: [],
        customizeBusinessTypeCode: undefined,
      },
      modelAll: [],
      customerList: [],
      columnList: [
        {
          tooltip: true,
          label: '单据编号',
          minWidth: '140px',
          fixed: 'left',
          prop: 'code',
          render: (h, { row }) => {
            return (
              <a
                class={'text-primary'}
                style={'cursor: pointer'}
                onClick={this.gotoDetail.bind(
                  this,
                  row.formDataMsg?.id,
                  row.formDataMsg?.typeMsg?.customizeSystemConfigCode,
                )}
              >
                {row.formDataMsg?.code}
              </a>
            );
          },
        },
        {
          tooltip: true,
          label: '单据类型',
          minWidth: '100px',
          prop: 'type',
          render: (h, { row }) => row.formDataMsg?.typeMsg?.name,
        },
        {
          tooltip: true,
          label: '状态',
          minWidth: '180px',
          prop: 'processStatusDesc',
        },
        {
          tooltip: true,
          label: '提交人',
          minWidth: '180px',
          prop: 'preApproveUserName',
        },
        {
          tooltip: true,
          label: '提交时间',
          minWidth: '160px',
          prop: 'preApproveTime',
          format: val => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          tooltip: true,
          label: '发起人',
          minWidth: '180px',
          prop: 'submitUserName',
        },
        {
          tooltip: true,
          label: '发起时间',
          minWidth: '160px',
          prop: 'submitTime',
          format: val => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          label: '操作',
          minWidth: '180px',
          prop: 'actions',
          fixed: 'right',
          render: (h, { row }) => {
            const buttonMsg = row.formDataMsg?.buttonMsg || [];
            let actions
              = buttonMsg?.length
                ? buttonMsg.map(btns => (
                  <span
                    v-auth={btns.pcAuthCode}
                    class="cursor-pointer text-primary mr-2"
                    style={{ wordBreak: 'keep-all' }}
                    onClick={this.gotoDetail.bind(
                      this,
                    row.formDataMsg?.id,
                    row.formDataMsg?.typeMsg?.customizeSystemConfigCode,
                    )}
                  >
                    {btns.name}
                  </span>
                )) : [];
            return actions;
          },
        },
      ],
    };
  },
  async created () {
    const { body: modelAll } = await getFormTypeListByConfigId({ useFlag: true });
    this.modelAll = modelAll;
  },
  methods: {
    getTableList () {
      this.customerList = [];
      if (this.form.timePeriod && this.form.timePeriod.length) {
        this.form.approveStartTime = this.form.timePeriod[0];
        this.form.approveEndTime = this.form.timePeriod[1];
      }
      if (this.form.createdTime && this.form.createdTime.length) {
        this.form.submitStartTime = this.form.createdTime[0];
        this.form.submitEndTime = this.form.createdTime[1];
      }
      const param = {
        ...this.form,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      delete param.timePeriod;
      delete param.createdTime;
      workbenchHandle(param).then(({ body }) => {
        const { list, total } = body;
        this.customerList = [...list] || [];
        this.pagination.total = total;
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      Object.keys(this.form).forEach(i => {
        this.form[i] = undefined;
      });
      this.form.taskTypeEnum = 'AGENT';
      this.handleSearch();
    },
    gotoDetail (id, customizeSystemConfigCode) {
      const path = `/customForm/detail/${customizeSystemConfigCode}`;
      this.$router.push({
        path,
        query: { id },
      });
    },
  },
};
</script>
